import redaxios from "redaxios";
import { FetchDeadLettersQueryResponse, SearchDeadletterCommand, SearchDeadletterCommandResponse } from "../types/apiModels/deadletters";
import { API_ROUTES } from "../types/constants/apiConsts";

export const fetchDeadLetters = async (
  AuthToken: string,
): Promise<FetchDeadLettersQueryResponse<any>> => {
  // get token from context
  if (!AuthToken) {
    throw new Error("No auth token");
  }
  // fetch dead letters
  let url = `${process.env.REACT_APP_API_BASE_URL ?? ""}${API_ROUTES.FETCH_DEADLETTERS}`;
  const response = await redaxios.get(url, {
    headers: {
      Authorization: `Basic ${AuthToken}`,
    },
  });
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }
  return response.data as FetchDeadLettersQueryResponse<any>;
};

export const requeueDeadLetter = async (
  AuthToken: string,
  deadLetterId: string
): Promise<boolean> => {
  // get token from context
  if (!AuthToken) {
    throw new Error("No auth token");
  }
  // requeue dead letter
  let url = `${process.env.REACT_APP_API_BASE_URL ?? ""}${API_ROUTES.FETCH_DEADLETTERS}/${deadLetterId}/requeue`;
  const res = await redaxios.patch(url, null, {
    headers: {
      Authorization: `Basic ${AuthToken}`,
    },
  });
  if (res.status === 202) {
    return true;
  }
  if (res.status === 401) {
    throw new Error("Unauthorized");
  }
  return false;
};

export const deleteDeadLetter = async (
  AuthToken: string,
  deadLetterId: string
): Promise<boolean> => {
  // get token from context
  if (!AuthToken) {
    throw new Error("No auth token");
  }
  // delete dead letter
  let url = `${process.env.REACT_APP_API_BASE_URL ?? ""}${API_ROUTES.FETCH_DEADLETTERS}/${deadLetterId}`;
  const res = await redaxios.delete(url, {
    headers: {
      Authorization: `Basic ${AuthToken}`,
    },
  });
  if (res.status === 204) {
    return true;
  }
  if (res.status === 401) {
    throw new Error("Unauthorized");
  }
  return false;
};


export const searchDeadLetters = async (
  AuthToken: string,
  searchTerm: string
): Promise<SearchDeadletterCommandResponse<any>> => {
  // get token from context
  if (!AuthToken) {
    throw new Error("No auth token");
  }
  // search dead letters
  const req: SearchDeadletterCommand = {
    searchTerm: searchTerm
  }
  let url = `${process.env.REACT_APP_API_BASE_URL ?? ""}${API_ROUTES.SEARCH_DEADLETTERS}`;
  const response = await redaxios.post(url, req, {
    headers: {
      Authorization: `Basic ${AuthToken}`,
    },
  });
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }
  return response.data as SearchDeadletterCommandResponse<any>;
}
