import { useState, useEffect } from "react";
import { DeadLetters } from "../types/DeadLetters";
import { IDeadLetterProps } from "../types/interfaces/ILeftpaneProps";

interface IEventTabProps {
  data: DeadLetters<any>[];
  setFilterBy: (filter: string) => void;
}

export const LeftDeadLetterPane = ({ data, setFilterBy }: IEventTabProps) => {
  interface IDDLGroup {
    serviceName: string;
    count: number;
  }
  const [serviceGroups, setServiceGroups] = useState<IDDLGroup[]>();
  useEffect(() => {
    if (data) {
      let obj: IDDLGroup[];
      obj = data.reduce((acc: IDDLGroup[], curr: DeadLetters<any>) => {
        const key = acc.find((k) => k.serviceName === curr.event.serviceName);
        if (key) {
          key.count++;
        } else {
          acc.push({
            serviceName: curr.event.serviceName ?? "NO NAME",
            count: 1,
          });
        }
        return acc;
      }, []);
      setServiceGroups(obj);
    }
  }, [data]);
  return (
    <div
      className={`h-full w-[14rem] flex flex-col gap-4 justify-start
    items-start text-pallette-3 bg-pallette-1 rounded-lg p-4 
    shadow-md drop-shadow-2xl backdrop-blur-2xl shadow-zinc-800  `}
    >
      <p className="text-4xl font-light">Queue Names:</p>
      {serviceGroups &&
        serviceGroups.map((key) => {
          return (
            <div
              className="w-full px-4 py-2 border-0 border-stone-300 border-b-2 
            text-center grid place-items-center h-12 hover:bg-[rgba(255,255,255,0.2)] hover:rounded-t-lg "
              onClick={() => {
                setFilterBy(key.serviceName);
              }}
            >
              {key.serviceName}:{key.count}
            </div>
          );
        })}
    </div>
  );
};
