// Auth Context
//
// This context is used to manage the authentication state of the application.
// It is used to store the user's JWT token and to provide a function to log in
// and log out.
// -----------------------------------------------------------------------------
import React from "react";
import { User } from "../types/users";

export interface AuthContextProps {
  AuthToken: string;
  setAuthToken: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = React.createContext<AuthContextProps>({
  AuthToken: "",
  setAuthToken: () => { },
});

// Language: typescript
