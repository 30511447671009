/*
 * API Routes Constants
 */
export const API_ROUTES = {
  LOGIN: "/api/v1/login",
  FETCH_DEADLETTERS: "/api/v1/deadletters",
  FETCH_DEADLETTER: "/api/v1/deadletters/{id}",
  REQUEUE_DEADLETTER: "/api/v1/deadletters/{id}/requeue",
  SEARCH_DEADLETTERS: "/api/v1/deadletters/search",
};
