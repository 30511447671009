import React from "react";
import { createSession } from "../api/sessions";
import { AuthContext } from "../contexts/authContext";

export default function LoginPage() {
  const { AuthToken, setAuthToken } = React.useContext(AuthContext);
  const [token, setToken] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const buttonRef = React.useRef<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    buttonRef.current?.addEventListener("keypress", (e: any) => {
      if (e.key === "Enter") {
        handleLogin();
      }
    });
  }, []);
  const handleLogin = async () => {
    setLoading(true);
    try {
      let res = await createSession(token);
      setAuthToken(res.token);
      // add token to local storage
      localStorage.setItem("authToken", res.token);
      const expiry = Date.now() + res.expiresIn;
      localStorage.setItem("authTokenExpiry", expiry + "");
    } catch (err: any) {
      setError(err.message);
    }
    setLoading(false);
  };
  return (
    <div ref={buttonRef} className="w-1/3 aspect-video rounded-2xl bg-stone-100 flex flex-col gap-3 justify-evenly items-start p-8">
      <h1 className="w-full text-center text-transparent bg-clip-text bg-gradient-to-r from-stone-800 to-stone-400 text-6xl p-3">
        Login
      </h1>
      <label className="text-stone-800 text-lg font-semibold">Token</label>
      <form className="w-full flex flex-col gap-3" onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}>
        <input
          className="w-full rounded-lg p-2 bg-stone-200 text-stone-800 outline-purple-500"
          value={token}
          type="password"
          onChange={(e) => setToken(e.target.value)}
        />
        <button
          id="login"
          ref={buttonRef}
          className="w-full rounded-lg p-2 bg-stone-200 text-stone-800 outline-purple-500 hover:bg-stone-300 mt-2 hover:scale-95"
          onClick={handleLogin}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
