import React from "react";
import { AuthContext } from "./contexts/authContext";
import "./App.css";
import LoginPage from "./Pages/login";
import Home from "./Pages";
function App() {
  const [AuthToken, setAuthToken] = React.useState<string>("");
  const authValue = React.useMemo(
    () => ({ AuthToken, setAuthToken }),
    [AuthToken, setAuthToken]
  );
  React.useEffect(() => {
    // fetch token from local storage
    const token = localStorage.getItem("authToken");
    const tokenExpiry = localStorage.getItem("authTokenExpiry");
    if (token && token.length > 0 && tokenExpiry && parseInt(tokenExpiry) > Date.now()) {
      setAuthToken(token);
    } else {
      setAuthToken("");
      console.log("no token found");
      // add token to local storage
      // localStorage.setItem("authToken", "token");
    }
  }, [authValue]);
  return (
    <AuthContext.Provider value={authValue}>
      <div className="min-h-screen min-w-screen bg-lessBlack flex flex-col items-center justify-center">
        {AuthToken && AuthToken !== "" ? <Home /> : <LoginPage />}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
