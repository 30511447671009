import { IEventTabProps } from "../types/interfaces/IEventTabProps";
import { requeueDeadLetter, deleteDeadLetter } from "../api/deadletters";
import { useContext, Fragment, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import { Dialog, Transition } from '@headlessui/react'
export const EventTab = ({ event, index }: IEventTabProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { AuthToken, setAuthToken } = useContext(AuthContext);
  const handleRequeueEvent = async () => {
    try {
      const success = await requeueDeadLetter(AuthToken, event.id);
    } catch (err) {
      const er = err as Error
      if (er.message === "Unauthorized") {
        setAuthToken("");
        return;
      }
      console.log(err);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      const success = await deleteDeadLetter(AuthToken, event.id);
    } catch (err) {
      const er = err as Error
      if (er.message === "Unauthorized") {
        setAuthToken("");
        return;
      }
      console.log(err);
    }
  };


  const requeueButton = () => {
    return (
      <button
        onClick={handleRequeueEvent}
        className="bg-emerald-600 text-pallette-3 font-semibold shadow-md 
        drop-shadow-lg backdrop-blur-lg shadow-zinc-800 rounded-lg p-2 hover:scale-95">
        Requeue
      </button>
    )
  }

  const deleteButton = () => {
    return (

      <button
        onClick={handleDeleteEvent}
        className="bg-red-600 text-pallette-3 font-semibold shadow-md 
        drop-shadow-lg backdrop-blur-lg shadow-zinc-800 rounded-lg p-2 hover:scale-95"
      >
        Delete
      </button>
    )
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  return (
    <div
      className="w-full h-full border-0 border-pallette-2 border-b-2
        text-left flex flex-row justify-start items-center hover:bg-[rgba(255,255,255,0.2)] hover:rounded-t-lg"
    >
      <p className="text-pallette-3 font-semibold p-2 border-zinc-500 border-r-2 w-10 text-left">
        {index + 1}
      </p>
      <div onClick={openModal} className="w-full h-full flex flex-row gap-4 justify-start items-center p-2">
        <div style={{ wordBreak: "break-word" }}>{JSON.stringify(event)}</div>
        {requeueButton()}
        {deleteButton()}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center flex-col gap-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-2/3 bg-stone-700 transform overflow-hidden rounded-2xl 
                p-6 text-left text-pallette-3 align-middle shadow-lg transition-all
                flex flex-col gap-4">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 "
                  >
                    {event.routing_key}
                  </Dialog.Title>
                  <div className="mt-2">
                    <textarea className="h-[30rem] w-full font-medium bg-stone-900 p-4 rounded-lg outline-none
                    text-pallette-3" value={JSON.stringify(event, null, 4)} readOnly />
                  </div>

                  <div className="mt-4 flex flex-row-reverse justify-between items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center border border-transparent 
                      bg-blue-100 px-4 py-2 text-sm  text-blue-900 hover:bg-blue-200 
                      focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2
                      font-semibold shadow-md drop-shadow-lg backdrop-blur-lg shadow-zinc-800 rounded-lg p-2 hover:scale-95"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <div className="flex flex-row gap-4 justify-center items-center">
                      {requeueButton()}
                      {deleteButton()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
