import { DeadLetters } from "../types/DeadLetters";
export const PaginateDdl = (
  data: DeadLetters<any>[] | null,
  page: number,
  items: number
) => {
  if (data) {
    const startIndex = (page - 1) * items;
    const endIndex = page * items;
    return data.slice(startIndex, endIndex);
  }
  return null;
};
