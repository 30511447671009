import { IDeadLetterProps } from "../types/interfaces/ILeftpaneProps";
import { EventTab } from "./EventTab";
export const DeadLetterBrowser = ({ data }: IDeadLetterProps) => {
  return (
    <div
      className="w-full h-full text-pallette-3 bg-gradient-to-b from-pallette-1 to-cyan-800 rounded-lg p-4 
      shadow-md drop-shadow-2xl backdrop-blur-2xl shadow-zinc-800 flex flex-col justify-start items-start gap-4"
    >
      <h2 className=" text-4xl text-pallette-3 font-light">DeadLetters:</h2>
      {data &&
        data.map((item, idx) => {
          return <EventTab event={item} index={idx} />;
        })}
    </div>
  );
};
