import { useEffect, useReducer, useState, useContext } from "react";
import { fetchDeadLetters, searchDeadLetters } from "../api/deadletters";
import { DeadLetterBrowser } from "../components/DeadLetterBrowser";
import FilterItem from "../components/filterItem";
import { LeftDeadLetterPane } from "../components/LeftDeadLetterPane";
import Paginate from "../components/paginate";
import Search from "../components/search";
import { DEAD_LETTER_EVENTS } from "../constants/appconsts";
import { AuthContext } from "../contexts/authContext";
import { SocketClient } from "../managers/socket";
import { FetchDeadLettersQueryResponse } from "../types/apiModels/deadletters";
import { DeadLetters } from "../types/DeadLetters";
import { SocketMessage } from "../types/socketMessage";
import { PaginateDdl } from "../utils/paginate";

export default function Home() {
  type DDlState = {
    DeadLetters: DeadLetters<any>[];
    Count: number;
    Page: number;
    PageSize: number;
    Search: string;
    Filter: string;
    PageItems: DeadLetters<any>[];
    TotalPages: number;
  };
  const [state, reducer] = useReducer((state: DDlState, action: any) => {
    switch (action.type) {
      case "ADD_DEAD_LETTER":
        return {
          ...state,
          DeadLetters: [...state.DeadLetters, action.payload],
          Count: state.Count + 1,
        };
      case "REMOVE_DEAD_LETTER":
        return {
          ...state,
          DeadLetters: state.DeadLetters.filter(
            (ddl) => ddl.id !== action.payload
          ),
          Count: state.Count - 1,
        };
      case "INIT_DEAD_LETTERS":
        const res = action.payload as FetchDeadLettersQueryResponse<any>;
        if (res.count === 0) {
          return {
            ...state,
            DeadLetters: [],
            Count: 0,
            PageItems: [],
            Filter: "",
            Search: "",
            TotalPages: 1,
            Page: 1,
          };
        }
        const ddls = res.data.sort((a, b) => {
          //@ts-ignore
          return b.event.createdDateTime - a.event.createdDateTime;
        });
        return {
          ...state,
          DeadLetters: ddls,
          Count: res.count,
          PageItems: PaginateDdl(ddls, state.Page ?? 1, state.PageSize ?? 10),
          Filter: "",
          Search: "",
          Page: 1,
          PageSize: 10,
          TotalPages: Math.ceil(res.count / 10),
        };
        return action.payload;
      case "FILTER_DEAD_LETTERS":
        const filterddls = state.DeadLetters.filter(
          (ddl) => ddl.event.serviceName === action.payload
        );
        return {
          ...state,
          Filter: action.payload,
          DeadLetters: filterddls,
          Count: filterddls.length,
          PageItems: PaginateDdl(
            filterddls,
            state.Page ?? 1,
            state.PageSize ?? 10
          ),
          TotalPages: Math.ceil(filterddls.length / 10),
          Page: 1,
        };
      case "SEARCH_DEAD_LETTERS":
        const ress = action.payload
          .apiResponse as FetchDeadLettersQueryResponse<any>;
        return {
          ...state,
          Search: action.payload.search,
          DeadLetters: ress.data,
          Count: ress.count,
          PageItems: PaginateDdl(
            ress.data,
            state.Page ?? 1,
            state.PageSize ?? 10
          ),
          TotalPages: Math.ceil(ress.count / 10),
          Page: 1,
        };
      case "SET_PAGE":
        return {
          ...state,
          Page: action.payload,
          PageItems: PaginateDdl(
            state.DeadLetters,
            action.payload,
            state.PageSize
          ),
        };
      case "SET_PAGE_SIZE":
        return {
          ...state,
          PageSize: action.payload,
          PageItems: PaginateDdl(state.DeadLetters, state.Page, action.payload),
          TotalPages: Math.ceil(state.Count / action.payload),
        };
      default:
        return state;
    }
  }, {});
  const { AuthToken, setAuthToken } = useContext(AuthContext);
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const cancelFilter = () => {
    initalizeDeadLetters();
  };
  const filterDeadLetters = (filter: string) => {
    reducer({ type: "FILTER_DEAD_LETTERS", payload: filter });
  };
  const initalizeDeadLetters = () => {
    fetchDeadLetters(AuthToken)
      .then((res) => {
        reducer({ type: "INIT_DEAD_LETTERS", payload: res });
        setLoading(false);
      })
      .catch((err) => {
        const er = err as Error;
        if (er.message === "Unauthorized") {
          setAuthToken("");
          return;
        }
        setError("Error fetching dead letters");
        setLoading(false);
      });
  };
  const searchDeadLettersHandler = async (search: string) => {
    if (!search || search.length === 0) {
      initalizeDeadLetters();
      return;
    }
    reducer({
      type: "SEARCH_DEAD_LETTERS",
      payload: {
        search: search,
        apiResponse: await searchDeadLetters(AuthToken, search),
      },
    });
  };
  const setPage = (page: number) => {
    reducer({ type: "SET_PAGE", payload: page });
  };
  useEffect(() => {
    setLoading(true);
    initalizeDeadLetters();
    try {
      SocketClient.getInstance().onUpdates(handleSocketUpdates);
    } catch (err) {
      setAuthToken("");
    }
  }, []);

  const handleSocketUpdates = async (dat: any) => {
    const message = dat as SocketMessage<any>;
    if (message.notificationType === DEAD_LETTER_EVENTS.ADDED_SOCKET_MESSAGE) {
      reducer({
        type: "ADD_DEAD_LETTER",
        payload: message.body as DeadLetters<any>,
      });
      return;
    }
    if (
      message.notificationType === DEAD_LETTER_EVENTS.DELETED_SOCKET_MESSAGE
    ) {
      const ddl = message.body as DeadLetters<any>;
      reducer({ type: "REMOVE_DEAD_LETTER", payload: ddl.event.id });
      return;
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="w-screen min-h-screen flex flex-col p-8 justify-start items-start gap-4">
      <div
        className="w-full flex flex-col justify-evenly items-start text-pallette-3 bg-pallette-1 
      rounded-lg p-4 shadow-md drop-shadow-2xl backdrop-blur-2xl shadow-zinc-800"
      >
        <h1 className="text-4xl font-light">Soded</h1>
        <p className="text-xl font-bold">
          Deadletters Pending In Queue: {state.Count}
        </p>
      </div>
      <Search search={state.Search} setSearch={searchDeadLettersHandler} />
      {state.Filter !== "" && (
        <div
          className="flex flex-row items-center gap-4 w-full bg-pallette-1 text-pallette-3
        rounded-lg p-4 shadow-md drop-shadow-2xl backdrop-blur-2xl shadow-zinc-800"
        >
          <p>Filter By:</p>
          <FilterItem name={state.Filter} cancel={cancelFilter} />
        </div>
      )}

      <div className="w-full flex flex-row h-full gap-2">
        <LeftDeadLetterPane
          data={state.DeadLetters}
          setFilterBy={filterDeadLetters}
        />
        <DeadLetterBrowser data={state.PageItems} />
      </div>
      <Paginate
        currentPage={state.Page}
        totalPages={state.TotalPages}
        itemsPerPage={state.PageSize}
        setCurrentPage={setPage}
      />
    </div>
  );
}
