import redaxios from "redaxios";
import { API_ROUTES } from "../types/constants/apiConsts";
import { SessionResponse } from "../types/apiModels/Session";
/*
createSession creates a new session for the user.
@param {string} authToken - The user's auth token.
@returns {Promise<SessionResponse>} - The session response.
*/
export const createSession = async (
  authToken: string
): Promise<SessionResponse> => {
  let url = `${process.env.REACT_APP_API_BASE_URL ?? ""}${API_ROUTES.LOGIN}`;
  const response = await redaxios.get(url, {
    headers: {
      Authorization: `Basic ${authToken}`,
    },
  });
  return response.data as SessionResponse;
};
