/*
SOCKETCLIENT
Author: Karim Hassan
DATE: 2022-12-12
*/
import { Pichu } from "pika-pichu";

export class SocketClient {
  private static instance: SocketClient;
  private constructor() { }
  private observers: Function[] = [];
  private client: Pichu | null = null;
  public static getInstance(): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient();
      const url =
        process.env.REACT_APP_API_BASE_URL?.replace("http", "ws") + "/ws";
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No token");
      }
      SocketClient.instance.client = new Pichu(url, token);
      SocketClient.instance.client.GetJSONMessage((data) => {
        SocketClient.instance.notifyObservers(data);
      });
    }
    return SocketClient.instance;
  }

  public onUpdates(fn: Function) {
    this.observers.push(fn);
  }

  private notifyObservers(data: any) {
    this.observers.forEach((fn) => {
      fn(data);
    });
  }
}
