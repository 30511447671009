export interface IPagationProps {
  currentPage: number;
  itemsPerPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
}

export default function Paginate(props: IPagationProps) {
  return (
    <div className="w-full flex flex-row gap-4 justify-center items-center">
      <button
        className="bg-pallette-3 text-pallette-1 rounded-lg p-2"
        onClick={() => props.setCurrentPage(1)}
      >
        First
      </button>
      <button
        className="bg-pallette-3 text-pallette-1 rounded-lg p-2"
        onClick={() => {
          if (props.currentPage > 1)
            props.setCurrentPage(props.currentPage - 1);
        }}
      >
        Prev
      </button>
      <p className="text-pallette-3 font-light">
        Page {props.currentPage} of {props.totalPages}
      </p>
      <button
        className="bg-pallette-3 text-pallette-1 rounded-lg p-2"
        onClick={() =>
          props.currentPage === props.totalPages
            ? null
            : props.setCurrentPage(props.currentPage + 1)
        }
      >
        Next
      </button>
      <button
        className="bg-pallette-3 text-pallette-1 rounded-lg p-2"
        onClick={() => props.setCurrentPage(props.totalPages)}
      >
        Last
      </button>
    </div>
  );
}
